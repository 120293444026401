import axios from "axios";
import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import CartTotals from "../components/partials/cart-totals";
import CartTotalsMobile from "../components/partials/cart-totals-mobile";
import CartWidget from "../components/partials/cart-widget";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import { styles } from "../utilities/styles";

const CheckoutStep2 = () => {
  const { checkoutInfo, contents, onStartCheckout, setCheckoutInfo, totals } =
    useContext(CartContext);

  useEffect(() => {
    if (!checkoutInfo?.shippingZip) {
      navigate("/checkout/");
    }

    if (typeof sessionStorage !== "undefined") {
      axios
        .post(`/.netlify/functions/start-checkout`, {
          email: checkoutInfo.email,
          checkoutInfo: checkoutInfo,
          cartTotals: totals,
          cartContents: contents,
          sourceUrl: sessionStorage.getItem("bpd-first-page"),
          referrer: sessionStorage.getItem("bpd-referrer"),
          value: totals?.total ? +totals?.total?.substr(1) : 0,
          gclid: localStorage.getItem("bpd-gclid"),
          fbclid: localStorage.getItem("bpd-fbclid"),
          utm_source: localStorage.getItem("utm_source"),
          utm_medium: localStorage.getItem("utm_medium"),
          utm_campaign: localStorage.getItem("utm_campaign"),
          msclkid: localStorage.getItem("msclkid"),
        })
        .then((response) => {
          if (response.data?.klaviyo?.cart_id) {
            localStorage.setItem(
              "bpd-cart-id",
              response.data?.klaviyo?.cart_id
            );
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  });

  useEffect(() => {
    onStartCheckout(2);
  }, []);

  return (
    <>
      <Seo title="Checkout Step 2" />
      <CartTotalsMobile />
      <div className="container px-4 mx-auto my-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-3/5 lg:pr-6">
            <h1 className="font-xxl mb-8">Installation Information</h1>
            <Formik
              initialValues={{
                shippingAddress: checkoutInfo.shippingAddress
                  ? checkoutInfo.shippingAddress
                  : "",
                shippingAddress2: checkoutInfo.shippingAddress2
                  ? checkoutInfo.shippingAddress2
                  : "",
                shippingCity: checkoutInfo.shippingCity
                  ? checkoutInfo.shippingCity
                  : "",
                shippingState: process.env.BILLING_STATE,
                shippingZip: checkoutInfo.shippingZip
                  ? checkoutInfo.shippingZip
                  : "",
              }}
              validate={(values) => {
                let errors = {};
                if (!values.shippingAddress) {
                  errors.shippingAddress = "Required";
                }
                if (!values.shippingCity) {
                  errors.shippingCity = "Required";
                }
                if (!values.shippingState) {
                  errors.shippingState = "Required";
                }
                if (!values.shippingZip) {
                  errors.shippingZip = "Required";
                }
                if (!values.shippingState) {
                  errors.shippingState = "Required";
                }
                return errors;
              }}
              onSubmit={(values) => {
                setCheckoutInfo({
                  ...checkoutInfo,
                  ...values,
                });
                navigate("/checkout-step-3/");
              }}
              enableReinitialize={true}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div className="mb-6">
                    <label htmlFor="shippingAddress" className="block mb-2">
                      <strong>Installation Address</strong>{" "}
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      type="text"
                      name="shippingAddress"
                      placeholder="Installation Address"
                      className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="shippingAddress"
                      component="div"
                    />
                  </div>

                  <div className="mb-6">
                    <Field
                      type="text"
                      name="shippingAddress2"
                      placeholder="Line 2"
                      className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="shippingAddress2"
                      component="div"
                    />
                  </div>

                  <div className="flex flex-wrap w-full items-stretch">
                    <div className="mb-6 w-1/2 pr-2">
                      <label htmlFor="shippingCity" className="block mb-2">
                        <strong>City</strong>{" "}
                        <abbr className="required" title="Required">
                          *
                        </abbr>
                      </label>
                      <Field
                        type="text"
                        name="shippingCity"
                        placeholder="City"
                        className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                      />
                      <ErrorMessage
                        className="text-red-dark text-sm mt-2"
                        name="shippingCity"
                        component="div"
                      />
                    </div>

                    <div className="mb-6 w-1/2 pl-2">
                      <label htmlFor="shippingState" className="block mb-2">
                        <strong>State</strong>{" "}
                        <abbr className="required" title="Required">
                          *
                        </abbr>
                      </label>
                      <Field
                        type="text"
                        name="shippingState"
                        placeholder="State"
                        className="bg-grey-lighter shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                        disabled
                      />
                      <ErrorMessage
                        className="text-red-dark text-sm mt-2"
                        name="shippingState"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="mb-6">
                    <label htmlFor="shippingZip" className="block mb-2">
                      <strong>Zip</strong>{" "}
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      type="text"
                      name="shippingZip"
                      className="bg-grey-lighter shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                      disabled
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="shippingZip"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 flex justify-between">
                    <Link
                      to="/checkout/"
                      className="block md:inline-block bg-grey text-white text-sm px-4 py-3 no-underline rounded uppercase"
                    >
                      Previous Step
                    </Link>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={classNames(
                        "block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-black text-sm px-4 py-3 no-underline rounded uppercase",
                        styles.button.default
                      )}
                    >
                      Next Step
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="w-full md:w-2/5 lg:pl-6">
            <CartWidget hideButtons={true} hideYouMayAlsoLike={true} />
            <CartTotals />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutStep2;
